import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Link = makeShortcode("Link");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-engineering-money",
      "style": {
        "position": "relative"
      }
    }}>{`💎 Engineering money`}<a parentName="h1" {...{
        "href": "#-engineering-money",
        "aria-label": " engineering money permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Video src="https://www.youtube-nocookie.com/embed/MxIrc1rxhyI" mdxType="Video" />
    <p>{`This Andreas Antonopoulos talk goes into more detail about the critical features of money and
what being able to engineer them really means. We consider it to be one of his more profound presentations.`}</p>
    <p>{`We've covered the collectible story of money, the links it has to reciprocal altruism and
the influence this has had on our evolution as a species, and the importance of closed loops of
circulation and lowered transaction costs. We've looked at the alternative story of debt, the
deep mythic and religious roots money and morality have in our human story, the link between
physical currency and violence, and ancient virtual monies and global trade networks. We've looked at modern banking methods, the need for `}<a parentName="p" {...{
        "href": "https://nakamoto.com/credible-neutrality/"
      }}>{`credibly neutral`}</a>{` currencies which can be programmed to respond in real time to the need for discipline or elasticity, and the importance of liquidity and the different prices of money to a truly global system. Now it's time to get down to engineering truly beneficial protocols for the creation and distribution of value.`}</p>
    <h2 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}><strong parentName="h2">{`How does this fit into Kernel?`}</strong><a parentName="h2" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This has been chosen for Kernel, in particular, because it speaks directly to:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Value`}</strong>{` and how we can use technology to engineer its creation and distribution.`}</p>
      <p>{`What `}<strong parentName="p">{`money`}</strong>{` as a protocol really means for engineers capable of writing economic code.`}</p>
      <p>{`How we can rethink `}<strong parentName="p">{`incentives`}</strong>{` now that we have overhauled the control architecture of money.`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"`}<strong parentName="p">{`Money is the killer app`}</strong>{`. Money is the killer app because it is the foundational technology
for all commerce and, as such, touches everything. In order to make all the stuff that is beyond
money - the "blockchain" applications - work as neutral, open, decentralized platforms, we need
to transact with a neutral, global, decentralized currency. You can't do the commerce, the trade,
the identity, the land registry, the `}<em parentName="p">{`everything else`}</em>{` without first having the foundation of a
fundamentally new way of doing money which is open, borderless, decentralized, censorship-resistant and global."`}</p>
    </blockquote>
    <p>{`Remember, money-as-a-protocol allows us to program incentives at scales never
before possible. Yes, this means that there are all sorts of interesting high-level use cases
to go after, but it also implies that the only way genuinely to do so is by thinking about the
incentives which underpin whatever you want to build. `}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 If you're not altering the incentive structure on which your society runs, you should
probably just use a database.`}</p>
    </blockquote>
    <p>{`One of our biggest problems is understanding the terms we use, and `}<em parentName="p">{`money`}</em>{` is probably the
most relevant example of this. In this course, we've described it as an abstraction to communicate value, as a language in its own right, and as a technology older than writing. 'Money' as such is not a definitional term, it's `}<strong parentName="p">{`a general classifier`}</strong>{`. We can, at least, talk about its critical features, defined in terms of how we can engineer it: `}</p>
    <Process mdxType="Process">
    
Store of value
      <p>{`Medium of exchange`}</p>
      <p>{`Unit of account. `}</p>
    </Process>
    <p>{`"Money" describes things which exhibit some behaviour that allows us to use that thing for one or more of the above purposes. Most importantly, there is always a trade-off between the three. Gold is a great store of value, but a lousy medium of exchange. Visa is a great medium of exchange, but not really a unit of account, or a store of value (as it generally represents a form of debt). When you throw tokens on a blockchain into the mix, things become really confusing, and this is because we're using a descriptive term - "money" - without differentiating between its actual uses.`}</p>
    <p>{`How do we decide which "things" satisfy the trade-off between these three critical features in
the most optimal way? We consider: scarcity, portability, durability, fungibility, divisibility,
acceptability and stability. In this context:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Metals aren't very good money, because they have some intrinsic value which is separate from
the money it represents. Silver and gold can be traded independently for aesthetic reasons or
other purposes. `}<strong parentName="p">{`This destroys its fundamental function as a medium of exchange.`}</strong>{`"`}</p>
    </blockquote>
    <p>{`It is a profound point, so pause here for a moment. Most people will tell you that intrinsic value is `}<em parentName="p">{`required`}</em>{` to make money "money", but the truth is almost the exact opposite. Money is an abstraction which allows us to speak meaningfully about the value of different items otherwise unrelated. If the medium we use to express such transactional relationships has its own intrinsic value, it is not a very functional abstraction.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What are the three traditional features of money we can engineer?`}</p>
        <p>{`Store of value, medium of exchange, and unit of account.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Which feature is most effected by money which has its own intrinsic value, like gold?`}</p>
        <p>{`Medium of exchange.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "changing-the-nature-of-control",
      "style": {
        "position": "relative"
      }
    }}>{`Changing the nature of control`}<a parentName="h3" {...{
        "href": "#changing-the-nature-of-control",
        "aria-label": "changing the nature of control permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Metals represented for humanity an era when we used the materials we found around us in the
world and adapted them to the uses for which they fit `}{`[...]`}{` Human beings looked around at found
objects in the world and asked 'How do we take these materials that we have and find suitable
uses for them?' At this point, we were adapting nature to the uses that we had, but only in a
peripheral way. Gradually, we became more intrusive in our adaptations and started `}<em parentName="p">{`moulding`}</em>{`
nature to our needs. We began interacting with nature at a molecular level, turning wood into
charcoal, or metal into alloys with unique properties better suited to our needs. Then came the
ability to affect the atomic nature of materials: synthetic fertilizers, fabrics, and plastics
etc. This is no longer about just finding materials and adapting them to our needs; it's about
creating materials which fit uses we have `}<em parentName="p">{`preconceived`}</em>{`. Today, we can affect the subatomic
structure of things - manipulating nature at the nanoscale to create carbon nanotubes etc.
That is, we are not just manipulating the atomic behaviour of materials; we are giving them new
physical properties.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`"We just did something similarly amazing to money. Until now, we have taken money as it exists
in the world, accepted the trade-offs different kinds imply between the three critical features
and used it as best we can. We accepted the compromise implicit in each type because we could
not change its fundamental nature. Take paper money: great medium of exchange and unit of
account; really terrible store of value because of its `}<strong parentName="p">{`control architecture`}</strong>{` (don't tell
your kids what inflation really does!). We adapt our uses to the material, and not the other
way around. `}<strong parentName="p">{`Until now, because now - for the very first time - we can engineer the fundamental
properties of money`}</strong>{`."`}</p>
    </blockquote>
    <p>{`Because the protocols for communicating value are now completely abstracted from the value which
they communicate, we can create the properties we need, as opposed to adapting the ones implicit
in any physical media. It's debatable whether this 'engineered money' has served as a good
medium of exchange and unit of account so far. This is because these two features have as much
to do with social contracts and the unit in which we choose to measure as they do with technical
protocols. Andreas' argument is that we can use abstract monetary protocols which we can
engineer to change gradually higher-order social contracts.`}</p>
    <h3 {...{
      "id": "new-conversations",
      "style": {
        "position": "relative"
      }
    }}>{`New conversations`}<a parentName="h3" {...{
        "href": "#new-conversations",
        "aria-label": "new conversations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"Now that we don't have to adapt to compromises, but can engineer trade-offs away, how about
we invent some characteristics of money that have never existed before? For instance, our money
is now also a universal ledger of transactions. A trackable, auditable ledger that can tell us
where money has been and what it has been used for. Or not. Because we can tweak the privacy
dial and say individuals should have privacy and governments should be accountable. `}<strong parentName="p">{`We can
reset that societal conversation`}</strong>{`."`}</p>
    </blockquote>
    <p>{`Again, this final sentence may seem glib, but it's an important point. We can literally reset
society-level conversations because there has been such a profound innovation in the media
through which we communicate value. What powerful narrative will your work speak accountable,
public truth to?`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What feature of money which has never existed before does Andreas argue we have already engineered?`}</p>
        <p>{`A universal ledger of transactions.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`"One of the results of compromising with the forms of money we use is that it is very difficult
to move between the choices you make: if you want to move between dollars and gold, it's not a
trivial procedure. However, if you want to move from a fully digital, owned asset that is a
perfect store of value, to a fully digital, owned asset that is a perfect medium of exchange;
you can now do that. Instead of trade-offs, we can just trade. I can disaggregate the fundamental
properties of money, on demand: even better, my wallet can do it for me, intelligently."`}</p>
    </blockquote>
    <p>{`The technology of money, at base, has always been composed of a system of symbols that allows
us to communicate value to one another. What is at issue with compromised versions is the
`}<strong parentName="p">{`control architecture`}</strong>{` required to navigate said compromises. Upending control architectures
might sound like a good thing to do and, in principle, it is, but remember:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"This morning someone said, 'Disrupt yourself!' Bullshit. Disruption isn't comfortable or
easy. Disruption means losing your job; it means cannibalizing your profit centre; it means
destroying your business. You don't do that to yourself! You act like Kodak - invent digital
photography in the 80's and then bury it and pretend that no-one knows, only to get upended by
Nokia who shipped a billion cameras, and they're not even a camera company `}{`[...]`}{` Engineered
money, built by a rag-tag crew of anarchists, cypherpunks and misfits who don't care about your
business plan, or about being legitimized by regulators, is similar. These people have, for
years now, been laying down on the ground `}<strong parentName="p">{`unassailable facts`}</strong>{` faster than anyone can keep up
with."`}</p>
    </blockquote>
    <p>{`What makes one man's fact another's fiction? Arguably; interpretation. However, when you can
engineer the linguistic protocol which communicates value between people, and create a common
standard for interpretation - i.e. a language compiler which produces the same output whenever
you feed it the same code - you get to what Andreas is talking about here: unassailable facts
that do not rely on any control architecture. They are true by virtue of the language in which
they are expressed, not because anyone in a position of authority said so.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: We can create any number of new features with money that is a perfect abstraction. However, what engineering money is `}<em parentName="p">{`really`}</em>{` about is changing the `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` which informs its creation and distribution.`}</p>
        <p>{`control architecture.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "further-references",
      "style": {
        "position": "relative"
      }
    }}>{`Further References`}<a parentName="h3" {...{
        "href": "#further-references",
        "aria-label": "further references permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`One question we may ask, upon realising that we can now engineer money, is "what new features are worth engineering?" Andreas has helped us observe one: a universal ledger of transactions, which has already had a stark impact on how we imagine transactions, work and organisation more broadly. Is that all we need?`}</p>
    <p>{`Kernel will suggest, over the next seven weeks, that we can consider at least one more critical feature as a part of our engineering efforts. Transactions, work and organisation are all about relationships and capabilities. Kernel asks, `}</p>
    <blockquote>
      <p parentName="blockquote">{`Can we create a culture where I am wealthy by virtue of what I care for, rather than what I control? Where wealth means “having enough to share” because who you are is already enough. Can we create environments where what I hold is valuable because of how it cultivates the expression of care within community?`}</p>
    </blockquote>
    <Video src="https://www.youtube-nocookie.com/embed/p1lXhTzlCVk?start=258" mdxType="Video" />
    <p>{`Much like Niran Babalola in `}<a parentName="p" {...{
        "href": "/learn/module-1/#week-1-firesides"
      }}>{`Module 1`}</a>{` we wish to reimagine `}<a parentName="p" {...{
        "href": "https://youtu.be/tV49vVMPsYo?t=346"
      }}>{`the meaning of welfare`}</a>{`, and suggest that we can enact this practically by `}</p>
    <Process mdxType="Process">
    
Developing our capability to relate (both internally and with others), and 
      <Link to="https://kortina.nyc/essays/principles-for-radical-tax-reform-and-a-universal-dividend/" mdxType="Link">
    Radical tax reform.
      </Link>
    </Process>
    <p>{`This is not to suggest that we financialize care, but rather that we figure out increasingly artistic ways of `}<a parentName="p" {...{
        "href": "https://so-far.online/a-new-circuit-reunion/"
      }}>{`incentivizing healthy relationships`}</a>{` of many different kinds. We seek to create a more reciprocal, convivial society:`}</p>
    <List mdxType="List">
      <Link to="https://theconvivialsociety.substack.com/p/the-paradox-of-control" mdxType="Link">
        <p>{`Resonance rather than control`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=rAL3L4-7r0M" mdxType="Link">
        <p>{`Make money beautiful`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      